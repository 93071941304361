import React, { createContext, useContext, useEffect, useState } from 'react';
import { companyApi } from '../api/company';
import { APIResponse, ErrorMessage, createErrorMessage, getResponseData } from '../api/core/types';
import type { Company_Metrics } from '../types/company';
import { useStatus } from './Status';

// Types
interface Chart_Dataset {
  Label: string;
  Data: number[];
  Background_Color?: string | string[];
  Border_Color?: string;
  Border_Width?: number;
  Fill?: boolean;
  Tension?: number;
}

interface Chart_Data {
  Labels: string[];
  Datasets: Chart_Dataset[];
}

interface Company_Chart_Data_Context_Type {
  Metrics: Company_Metrics[];
  Time_Series_Data: Chart_Data;
  Risk_Distribution: Chart_Data;
  Critical_Distribution: Chart_Data;
  Gender_Distribution: Chart_Data;
  Is_Loading: boolean;
  Error_Message: ErrorMessage | null;
  Meta: APIResponse<Company_Metrics>['meta'] | null;
  Query_Context: APIResponse<Company_Metrics>['data']['Query_Context'] | null;
  Analytics: APIResponse<Company_Metrics>['data']['Analytics'] | null;
  Refresh_Data: () => Promise<void>;
}

const DEFAULT_CHART_DATA: Chart_Data = {
  Labels: [],
  Datasets: []
};

const Company_Chart_Data_Context = createContext<Company_Chart_Data_Context_Type>({
  Metrics: [],
  Time_Series_Data: DEFAULT_CHART_DATA,
  Risk_Distribution: DEFAULT_CHART_DATA,
  Critical_Distribution: DEFAULT_CHART_DATA,
  Gender_Distribution: DEFAULT_CHART_DATA,
  Is_Loading: false,
  Error_Message: null,
  Meta: null,
  Query_Context: null,
  Analytics: null,
  Refresh_Data: async () => {}
});

// Color schemes
const COLOR_SCHEMES = {
  risk: {
    high: '#EF4444',    // Red
    medium: '#F59E0B',  // Yellow
    low: '#10B981',     // Green
    background: ['#EF4444', '#F59E0B', '#10B981'] as string[]
  },
  critical: {
    critical: '#EF4444',       // Red
    nonCritical: '#6B7280',    // Gray
    background: ['#EF4444', '#6B7280'] as string[]
  },
  gender: {
    male: '#3B82F6',          // Blue
    female: '#EC4899',        // Pink
    background: ['#3B82F6', '#EC4899'] as string[]
  },
  timeSeries: {
    riskScore: '#EF4444',      // Red
    criticalScore: '#F59E0B'   // Yellow
  }
};

// Helper functions
const Transform_To_Time_Series = (metrics: Company_Metrics[]): Chart_Data => {
  console.log('[CompanyChartData] Transform_To_Time_Series input:', metrics);
  
  const Labels = metrics.map(m => m.Period_Date || new Date().toISOString());

  // Convert currency string to number for charting
  const getRiskValue = (value: string | null): number => {
    if (!value || value === '$0.00') return 0;
    // Remove currency formatting but preserve the numeric value
    const cleanValue = value.replace(/[$,]/g, '');
    const parsed = parseFloat(cleanValue);
    console.log('[CompanyChartData] Parsing risk value:', {
      original: value,
      cleaned: cleanValue,
      parsed: parsed
    });
    return parsed || 0;
  };

  const chartData = {
    Labels,
    Datasets: [
      // Risk Score series
      {
        Label: 'Total Risk',
        Data: metrics.map(m => {
          const value = getRiskValue(m.total_risk);
          console.log('[CompanyChartData] Total Risk value:', {
            date: m.Period_Date,
            raw: m.total_risk,
            parsed: value
          });
          return value;
        }),
        Border_Color: COLOR_SCHEMES.timeSeries.riskScore,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      },
      {
        Label: 'Critical Risk',
        Data: metrics.map(m => {
          const value = getRiskValue(m.total_risk_critical);
          console.log('[CompanyChartData] Critical Risk value:', {
            date: m.Period_Date,
            raw: m.total_risk_critical,
            parsed: value
          });
          return value;
        }),
        Border_Color: COLOR_SCHEMES.timeSeries.criticalScore,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      },
      // Risk Level series
      {
        Label: 'High Risk',
        Data: metrics.map(m => {
          const value = m.count_red || 0;
          console.log('[CompanyChartData] High Risk count:', {
            date: m.Period_Date,
            value: value
          });
          return value;
        }),
        Border_Color: COLOR_SCHEMES.risk.high,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      },
      {
        Label: 'Medium Risk',
        Data: metrics.map(m => {
          const value = m.count_yellow || 0;
          console.log('[CompanyChartData] Medium Risk count:', {
            date: m.Period_Date,
            value: value
          });
          return value;
        }),
        Border_Color: COLOR_SCHEMES.risk.medium,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      },
      {
        Label: 'Low Risk',
        Data: metrics.map(m => {
          const value = (m.count_total || 0) - (m.count_red || 0) - (m.count_yellow || 0);
          console.log('[CompanyChartData] Low Risk count:', {
            date: m.Period_Date,
            total: m.count_total,
            red: m.count_red,
            yellow: m.count_yellow,
            calculated: value
          });
          return Math.max(0, value);
        }),
        Border_Color: COLOR_SCHEMES.risk.low,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      },
      // Gender series
      {
        Label: 'Male',
        Data: metrics.map(m => {
          const value = m.count_male || 0;
          console.log('[CompanyChartData] Male count:', {
            date: m.Period_Date,
            value: value
          });
          return value;
        }),
        Border_Color: COLOR_SCHEMES.gender.male,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      },
      {
        Label: 'Female',
        Data: metrics.map(m => {
          const value = m.count_female || 0;
          console.log('[CompanyChartData] Female count:', {
            date: m.Period_Date,
            value: value
          });
          return value;
        }),
        Border_Color: COLOR_SCHEMES.gender.female,
        Border_Width: 2,
        Fill: false,
        Tension: 0.4
      }
    ]
  };
  console.log('[CompanyChartData] Transform_To_Time_Series output:', chartData);
  return chartData;
};

const Transform_To_Chart_Data = (data: Record<string, number>, type: 'risk' | 'critical' | 'gender'): Chart_Data => {
  console.log(`[CompanyChartData] Transform_To_Chart_Data input for ${type}:`, data);
  const chartData = {
    Labels: Object.keys(data),
    Datasets: [
      {
        Label: type === 'risk' ? 'Risk_Level' : 
               type === 'critical' ? 'Critical_Risk' : 
               'Gender',
        Data: Object.values(data),
        Background_Color: COLOR_SCHEMES[type].background,
        Border_Width: 1
      }
    ]
  };
  console.log(`[CompanyChartData] Transform_To_Chart_Data output for ${type}:`, chartData);
  return chartData;
};

const Calculate_Distributions = (metrics: Company_Metrics[]) => {
  console.log('[CompanyChartData] Calculate_Distributions input:', metrics);
  // Use the latest metrics for distribution data
  const Latest_Metric = metrics[metrics.length - 1];
  console.log('[CompanyChartData] Latest metric:', Latest_Metric);
  if (!Latest_Metric) {
    return {
      risk: {
        'High Risk': 0,
        'Medium Risk': 0,
        'Low Risk': 0
      },
      critical: {
        'Critical': 0,
        'Non-Critical': 0
      },
      gender: {
        'Male': 0,
        'Female': 0
      }
    };
  }

  const total = Latest_Metric.count_total ?? 0;
  const critical = Latest_Metric.count_total_critical ?? 0;

  return {
    risk: {
      'High Risk': Latest_Metric.count_red ?? 0,
      'Medium Risk': Latest_Metric.count_yellow ?? 0,
      'Low Risk': Math.max(0, total - (Latest_Metric.count_red ?? 0) - (Latest_Metric.count_yellow ?? 0))
    },
    critical: {
      'Critical': critical,
      'Non-Critical': Math.max(0, total - critical)
    },
    gender: {
      'Male': Latest_Metric.count_male ?? 0,
      'Female': Latest_Metric.count_female ?? 0
    }
  };
};

interface Company_Chart_Data_Provider_Props {
  children: React.ReactNode;
}

export const Company_Chart_Data_Provider: React.FC<Company_Chart_Data_Provider_Props> = ({ children }) => {
  console.log('[CompanyChartData] Provider mounting');
  const { Company_ID, Has_Client_Context } = useStatus();
  console.log('[CompanyChartData] Status context:', { Company_ID, Has_Client_Context });

  const [Metrics, Set_Metrics] = useState<Company_Metrics[]>([]);
  const [Time_Series_Data, Set_Time_Series_Data] = useState<Chart_Data>(DEFAULT_CHART_DATA);
  const [Risk_Distribution, Set_Risk_Distribution] = useState<Chart_Data>(DEFAULT_CHART_DATA);
  const [Critical_Distribution, Set_Critical_Distribution] = useState<Chart_Data>(DEFAULT_CHART_DATA);
  const [Gender_Distribution, Set_Gender_Distribution] = useState<Chart_Data>(DEFAULT_CHART_DATA);
  const [Is_Loading, Set_Is_Loading] = useState(false);
  const [Error_Message, Set_Error_Message] = useState<ErrorMessage | null>(null);
  const [Meta, Set_Meta] = useState<APIResponse<Company_Metrics>['meta'] | null>(null);
  const [Query_Context, Set_Query_Context] = useState<APIResponse<Company_Metrics>['data']['Query_Context'] | null>(null);
  const [Analytics, Set_Analytics] = useState<APIResponse<Company_Metrics>['data']['Analytics'] | null>(null);

  const Fetch_Data = async () => {
    console.log('[CompanyChartData] Fetch_Data called with Company_ID:', Company_ID);
    if (!Company_ID || !Has_Client_Context) {
      console.log('[CompanyChartData] Missing required context, skipping fetch:', { Company_ID, Has_Client_Context });
      return;
    }

    Set_Is_Loading(true);
    Set_Error_Message(null);

    try {
      console.log('[CompanyChartData] Fetching metrics for Company_ID:', Company_ID);
      const response = await companyApi.Get_Company_Dashboard_Metrics(Company_ID);
      console.log('[CompanyChartData] Raw API response:', response);
      const metricsData = getResponseData(response);
      console.log('[CompanyChartData] Extracted metrics data:', metricsData);
      
      // Since getResponseData throws on error, we can safely assert response type
      const successResponse = response as APIResponse<Company_Metrics>;
      
      // Store meta information
      Set_Meta(successResponse.meta);
      Set_Query_Context(successResponse.data.Query_Context || null);
      Set_Analytics(successResponse.data.Analytics || null);
      
      console.log('[CompanyChartData] Metrics fetched successfully:', metricsData.length, 'records');
      console.log('[CompanyChartData] Setting metrics and chart data...');
      Set_Metrics(metricsData);
      Set_Time_Series_Data(Transform_To_Time_Series(metricsData));
      const { risk, critical, gender } = Calculate_Distributions(metricsData);
      Set_Risk_Distribution(Transform_To_Chart_Data(risk, 'risk'));
      Set_Critical_Distribution(Transform_To_Chart_Data(critical, 'critical'));
      Set_Gender_Distribution(Transform_To_Chart_Data(gender, 'gender'));
      console.log('[CompanyChartData] Data transformation complete');
    } catch (error) {
      const errorMsg = createErrorMessage(error, 'Failed to fetch company metrics');
      console.error('[CompanyChartData] Error fetching metrics:', errorMsg);
      Set_Error_Message(errorMsg);
    } finally {
      Set_Is_Loading(false);
    }
  };

  useEffect(() => {
    console.log('[CompanyChartData] Company_ID or Has_Client_Context changed:', { Company_ID, Has_Client_Context });
    if (Has_Client_Context) {
      console.log('[CompanyChartData] Client context ready, fetching data');
      Fetch_Data();
    } else {
      console.log('[CompanyChartData] Waiting for client context');
    }
  }, [Company_ID, Has_Client_Context]);

  const Context_Value: Company_Chart_Data_Context_Type = {
    Metrics,
    Time_Series_Data,
    Risk_Distribution,
    Critical_Distribution,
    Gender_Distribution,
    Is_Loading,
    Error_Message,
    Meta,
    Query_Context,
    Analytics,
    Refresh_Data: Fetch_Data
  };

  console.log('[CompanyChartData] Rendering provider with state:', {
    Is_Loading,
    Has_Error: !!Error_Message,
    Metrics_Count: Metrics.length,
    Has_Client_Context
  });

  return (
    <Company_Chart_Data_Context.Provider value={Context_Value}>
      {children}
    </Company_Chart_Data_Context.Provider>
  );
};

export const Use_Company_Chart_Data = () => {
  const context = useContext(Company_Chart_Data_Context);
  if (!context) {
    throw new Error('Use_Company_Chart_Data must be used within a Company_Chart_Data_Provider');
  }
  return context;
};

// Export types with new naming
export type { Chart_Data, Chart_Dataset, Company_Chart_Data_Context_Type };

// For backward compatibility
  export type {
    Chart_Data as ChartData,
    Chart_Dataset as ChartDataset,
    Company_Chart_Data_Context_Type as CompanyChartDataContextType
  };

// Export context with new and old names
export default Company_Chart_Data_Context;
export const CompanyChartDataContext = Company_Chart_Data_Context;
