import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientsApi } from '../api/clients';
import { isSuccessResponse } from '../api/core/types';
import { userApi } from '../api/user';
import { authApi } from '../features/auth/api';
import type { Client, ClientCompany } from '../types/client';
import type { StatusContextValue, StatusState } from '../types/contexts';
import type { User } from '../types/user';

const StatusContext = createContext<StatusContextValue | null>(null);

export const useStatus = () => {
  const context = useContext(StatusContext);
  if (!context) {
    throw new Error('useStatus must be used within a StatusProvider');
  }
  return context;
};

interface StatusProviderProps {
  children: React.ReactNode;
}

export const StatusProvider: React.FC<StatusProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<StatusState>({
    Is_Super_Admin: false,
    User_Entitlements: [],
    Is_Loading: false,
    Auth_Status: false,
    Company_ID: null,
    Error_Message: null,
    Email_Address: null,
    Client_ID: null,
    Client_Name: null,
    Company_Name: null,
    Has_Client_Context: false
  });

  const Check_User_Status = async (User_Email: string) => {
    console.log('[Status] Starting Check_User_Status for:', User_Email);
    try {
      // Get user data first to check super admin status
      console.log('[Status] Fetching user data...');
      const response = await userApi.Get_User({ Email_Address: User_Email });
      if (!isSuccessResponse(response)) {
        throw new Error(response.message || response.error_details || 'Failed to get user details');
      }
      const user = response.data.Records[0] as User;
      const Is_Super = Boolean(user.Super_Admin);

      console.log('[Status] User data fetched, Is_Super:', Is_Super);
      // Update state with user info
      setState(prev => ({
        ...prev,
        Is_Super_Admin: Is_Super,
        Email_Address: User_Email
      }));

      // Route based on user status
      if (Is_Super) {
        navigate('/super/dashboard');
        return; // Exit early for super admins
      }

      console.log('[Status] Getting context for non-super user...');
      // Get context from entitlements for non-super users
      try {
        const entitlementsResponse = await userApi.Get_User_Entitlements({ Email_Address: User_Email });
        if (!isSuccessResponse(entitlementsResponse)) {
          throw new Error(entitlementsResponse.message || entitlementsResponse.error_details);
        }
        const entitlements = entitlementsResponse.data.Records;
        const firstEntitlement = entitlements[0];

        if (firstEntitlement) {
          const User_Client_ID = firstEntitlement.Client_ID;
          const User_Company_ID = firstEntitlement.Company_ID;

          if (User_Client_ID && User_Company_ID) {
            console.log('[Status] Fetching client and company details...');
            // Get client and company names from API
            const clientsResponse = await clientsApi.Get_Clients();
            if (!isSuccessResponse(clientsResponse)) {
              throw new Error('Failed to get clients');
            }
            const Client = clientsResponse.data.Records.find((c: Client) => c.Client_ID === User_Client_ID);

            const companiesResponse = await clientsApi.Get_Companies(User_Client_ID);
            if (!isSuccessResponse(companiesResponse)) {
              throw new Error('Failed to get companies');
            }
            const Company = companiesResponse.data.Records.find((c: ClientCompany) => c.Company_ID === User_Company_ID);

            console.log('[Status] Setting client context state...', {
              User_Client_ID,
              User_Company_ID,
              Client_Name: Client?.Client_Name,
              Company_Name: Company?.Company_Name
            });
            // Update state first, then navigate
            await new Promise<void>(resolve => {
              setState(prev => {
                const newState = {
                  ...prev,
                  Client_ID: User_Client_ID,
                  Company_ID: User_Company_ID,
                  Client_Name: Client?.Client_Name || User_Client_ID,
                  Company_Name: Company?.Company_Name || User_Company_ID,
                  Has_Client_Context: true,
                  User_Entitlements: entitlements
                };
                console.log('[Status] New state:', {
                  Client_ID: newState.Client_ID,
                  Company_ID: newState.Company_ID,
                  Has_Client_Context: newState.Has_Client_Context
                });
                return newState;
              });
              resolve();
            });
            console.log('[Status] State updated, navigating to admin dashboard...');
            navigate('/admin/dashboard');
          }
        }
      } catch (error) {
        console.error('Failed to get user entitlements:', error);
        setState(prev => ({
          ...prev,
          Has_Client_Context: false,
          Client_ID: null,
          Company_ID: null,
          Client_Name: null,
          Company_Name: null,
          User_Entitlements: []
        }));
        throw error;
      }
    } catch (error) {
      console.error('Failed to check user status:', error);
      setState(prev => ({
        ...prev,
        Is_Super_Admin: false,
        Has_Client_Context: false,
        Client_ID: null,
        Company_ID: null,
        Client_Name: null,
        Company_Name: null,
        Error_Message: 'Failed to verify user role',
        User_Entitlements: []
      }));
      throw error;
    }
  };

  const authenticate = async (username: string, password: string) => {
    console.log('[Status] Starting authentication...');
    try {
      setState(prev => ({ ...prev, Is_Loading: true, Error_Message: null }));
      
      console.log('[Status] Authenticating with Cognito...');
      // First authenticate with Cognito
      const cognitoUser = await authApi.Authenticate(username, password);
      
      console.log('[Status] Authentication successful, setting auth state...');
      // Set auth status and check user status
      setState(prev => ({
        ...prev,
        Auth_Status: true,
        Email_Address: username
      }));

      console.log('[Status] Checking user status...');
      await Check_User_Status(username);
    } catch (error) {
      console.error('Authentication failed:', error);
      setState(prev => ({
        ...prev,
        Is_Loading: false,
        Auth_Status: false,
        Error_Message: error instanceof Error ? error.message : 'Authentication failed',
        Email_Address: null,
        Is_Super_Admin: false,
        User_Entitlements: []
      }));
      throw error;
    } finally {
      setState(prev => ({ ...prev, Is_Loading: false }));
    }
  };

  const logout = async () => {
    try {
      await authApi.Logout();
      setState(prev => ({
        ...prev,
        Auth_Status: false,
        Is_Super_Admin: false,
        User_Entitlements: [],
        Company_ID: null,
        Email_Address: null,
        Error_Message: null,
        Client_ID: null,
        Client_Name: null,
        Company_Name: null,
        Has_Client_Context: false
      }));
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      // Still clear state even if logout fails
      setState(prev => ({
        ...prev,
        Auth_Status: false,
        Is_Super_Admin: false,
        User_Entitlements: [],
        Company_ID: null,
        Email_Address: null,
        Error_Message: null,
        Client_ID: null,
        Client_Name: null,
        Company_Name: null,
        Has_Client_Context: false
      }));
      navigate('/');
    }
  };

  const checkAuthStatus = async () => {
    try {
      setState(prev => ({ ...prev, Is_Loading: true }));
      
      // Check Cognito session
      const cognitoUser = await authApi.Get_Current_Session();
      const session = await new Promise((resolve, reject) => {
        cognitoUser.getSession((err: any, session: any) => {
          if (err) reject(err);
          else resolve(session);
        });
      });
      
      // Get user attributes from session
      const attributes = authApi.Parse_User_Attributes(session);
      
      if (!attributes.email) {
        throw new Error('No email in session');
      }

      // Set auth status and check user status
      setState(prev => ({
        ...prev,
        Auth_Status: true,
        Email_Address: attributes.email
      }));

      await Check_User_Status(attributes.email);
    } catch (error) {
      console.error('Auth check failed:', error);
      setState(prev => ({
        ...prev,
        Is_Loading: false,
        Auth_Status: false,
        Is_Super_Admin: false,
        User_Entitlements: [],
        Company_ID: null,
        Email_Address: null,
        Client_ID: null,
        Client_Name: null,
        Company_Name: null,
        Has_Client_Context: false
      }));
    } finally {
      setState(prev => ({ ...prev, Is_Loading: false }));
    }
  };

  const Set_Client_Context = useCallback(async (clientId: string, companyId: string) => {
    console.log('[Status] Set_Client_Context called:', { 
      clientId, 
      companyId, 
      Is_Super_Admin: state.Is_Super_Admin,
      Current_State: {
        Client_ID: state.Client_ID,
        Company_ID: state.Company_ID,
        Has_Client_Context: state.Has_Client_Context
      }
    });

    if (!state.Is_Super_Admin) {
      console.log('[Status] Set_Client_Context ignored - not super admin');
      return;
    }

    try {
      // Get client details
      console.log('[Status] Fetching client details...');
      const clientResponse = await clientsApi.Get_Clients();
      if (!isSuccessResponse(clientResponse)) {
        throw new Error('Failed to get client details');
      }
      const client = clientResponse.data.Records.find((c: Client) => c.Client_ID === clientId);
      
      // Get company details
      console.log('[Status] Fetching company details...');
      const companyResponse = await clientsApi.Get_Companies(clientId);
      if (!isSuccessResponse(companyResponse)) {
        throw new Error('Failed to get company details');
      }
      const company = companyResponse.data.Records.find((c: ClientCompany) => c.Company_ID === companyId);

      // Update state and wait for it to complete
      console.log('[Status] Updating state...');
      const updatePromise = new Promise<void>(resolve => {
        setState(prev => {
          const newState = {
            ...prev,
            Client_ID: clientId,
            Company_ID: companyId,
            Client_Name: client?.Client_Name || clientId,
            Company_Name: company?.Company_Name || companyId,
            Has_Client_Context: true
          };
          console.log('[Status] New state:', newState);
          // Use requestAnimationFrame to ensure state update is processed
          requestAnimationFrame(() => resolve());
          return newState;
        });
      });

      await updatePromise;

      // Navigate after state update is confirmed
      console.log('[Status] State update complete, navigating to admin dashboard...');
      navigate('/admin/dashboard');
      console.log('[Status] Navigation complete');

    } catch (error) {
      console.error('[Status] Failed to set client context:', error);
      
      // Fallback: Update state and wait for it to complete
      console.log('[Status] Using fallback state update...');
      const fallbackPromise = new Promise<void>(resolve => {
        setState(prev => {
          const newState = {
            ...prev,
            Client_ID: clientId,
            Company_ID: companyId,
            Client_Name: clientId,
            Company_Name: companyId,
            Has_Client_Context: true
          };
          console.log('[Status] New state (fallback):', newState);
          // Use requestAnimationFrame to ensure state update is processed
          requestAnimationFrame(() => resolve());
          return newState;
        });
      });

      await fallbackPromise;

      // Navigate after state update is confirmed
      console.log('[Status] Fallback state update complete, navigating to admin dashboard...');
      navigate('/admin/dashboard');
      console.log('[Status] Navigation complete (fallback)');
    }
  }, [state.Is_Super_Admin, setState, navigate]);

  const Clear_Client_Context = useCallback(() => {
    console.log('[Status] Clear_Client_Context called');
    if (state.Is_Super_Admin) {
      setState(prev => {
        console.log('[Status] Clearing client context');
        return {
          ...prev,
          Client_ID: null,
          Company_ID: null,
          Client_Name: null,
          Company_Name: null,
          Has_Client_Context: false
        };
      });
      navigate('/super/dashboard');
    } else {
      console.log('[Status] Clear_Client_Context ignored - not super admin');
    }
  }, [state.Is_Super_Admin, navigate]);

  return (
    <StatusContext.Provider
      value={{
        ...state,
        authenticate,
        logout,
        determineInitialRoute: () => {
          if (!state.Auth_Status) return '/signin';
          if (state.Is_Super_Admin) return '/super/dashboard';
          return '/admin/dashboard';
        },
        checkAuthStatus,
        Set_Client_Context,
        Clear_Client_Context
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export default StatusContext;
