import type { Config_Action_Payload } from '../../types/action';
import { API_ENDPOINTS, Make_Authorized_API_Call } from '../core/apiClient';
import type { APIResult } from '../core/types';
import type { Action, Action_Params, Config_Action } from './types';

export interface Action_Init_Payload {
    Company_ID: string;
    Action_ID: string;
    Employee_ID: string;
    Initiator_ID: string;
    Description: string;
    Approval_Notes?: string;
    Required_Fields: Record<string, string>;
    Optional_Fields: Record<string, string>;
    Action_Data?: {
        workflow?: {
            // Sequential approval groups
            Approval_Group_1?: string | null;
            Approval_Group_2?: string | null;
            Approval_Group_3?: string | null;
            Approval_Group_4?: string | null;
            Approval_Group_5?: string | null;
            Approval_Group_6?: string | null;
            Approval_Group_7?: string | null;
            Approval_Group_8?: string | null;
            // Workflow visualization data
            steps: Array<{
                Step_ID: string;
                Step_Name: string;
                Order: number;
            }>;
            flowData: {
                nodes: any[];
                links: any[];
            };
        };
    };
}

export const actionApi = {
    /**
     * Config Actions
     */
    Get_Action_Configs: async (Company_ID: string): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.CONFIG.ACTIONS}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    Create_Action_Config: async (data: Config_Action_Payload): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.CONFIG.ACTIONS,
            'POST',
            data
        );
    },

    Update_Action_Config: async (Action_ID: string, data: Config_Action_Payload): Promise<APIResult<Config_Action>> => {
        // Remove duplicate status field
        const { Status, ...cleanData } = data;
        
        // Ensure field types are preserved and Action_ID is included
        const payload = {
            ...cleanData,
            Action_ID,
            // Keep the field types as they are since they're already correct from the form
            Required_Fields: cleanData.Required_Fields,
            Optional_Fields: cleanData.Optional_Fields,
            // Ensure single status field
            Action_Status: cleanData.Action_Status || 'Active'
        };
        
        return Make_Authorized_API_Call(
            API_ENDPOINTS.CONFIG.ACTIONS,
            'PUT',
            payload
        );
    },

    Delete_Action_Config: async (Action_ID: string): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.CONFIG.ACTIONS,
            'DELETE',
            { Action_ID }
        );
    },

    /**
     * Get actions
     */
    Get_Actions: async (params: Action_Params): Promise<APIResult<Action>> => {
        const queryParams = new URLSearchParams();
        
        // Add required parameters
        queryParams.append('Company_ID', params.Company_ID);
        
        // Add Active_Approver parameter (use Employee_ID as fallback)
        if (params.Active_Approver || params.Employee_ID) {
            queryParams.append('Active_Approver', params.Active_Approver || params.Employee_ID || '');
        }
        
        // Add optional parameters
        if (params.Action_Type) queryParams.append('Action_Type', params.Action_Type);

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.PENDING}?${queryParams.toString()}`,
            'GET'
        );
    },

    /**
     * Get completed actions
     */
    Get_Completed_Actions: async (params: Action_Params): Promise<APIResult<Action>> => {
        const queryParams = new URLSearchParams();
        queryParams.append('Company_ID', params.Company_ID);
        if (params.Employee_ID) queryParams.append('Employee_ID', params.Employee_ID);

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.COMPLETED}?${queryParams.toString()}`,
            'GET'
        );
    },

    /**
     * Get action details
     */
    Get_Action: async (params: Action_Params & { Action_ID: string }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.STATS}?Company_ID=${params.Company_ID}&Action_ID=${params.Action_ID}`,
            'GET'
        );
    },

    /**
     * Get company-wide action stats
     */
    Get_Action_Stats: async (Company_ID: string): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.STATS}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    /**
     * Create action
     */
    Create_Action: async (data: Action_Init_Payload): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.COMPANY.ACTION.INITIATE,
            'POST',
            data
        );
    },

    /**
     * Update and advance action
     */
    Update_Action: async (params: Action_Params & { 
        Action_ID: string;
        Status?: string;
        Comments?: string;
    }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.COMPANY.ACTION.ADVANCE,
            'PUT',
            params
        );
    },

    /**
     * Reject action
     */
    Reject_Action: async (params: Action_Params & { 
        Action_ID: string;
        Comments: string;
    }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.COMPANY.ACTION.REJECT,
            'PUT',
            params
        );
    }
};
