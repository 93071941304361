import { Navbar } from 'flowbite-react';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const AdminSecondaryNav: React.FC = () => {
    return (
        <Navbar fluid className="border-b">
            <div className="flex md:order-2" />
            <Navbar.Collapse>
                <NavLink
                    to="/admin/workflow"
                    className={({ isActive }) =>
                        `text-sm ${isActive ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`
                    }
                >
                    Workflow
                </NavLink>
                <NavLink
                    to="/admin/performance"
                    className={({ isActive }) =>
                        `text-sm ${isActive ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`
                    }
                >
                    Performance
                </NavLink>
                <NavLink
                    to="/admin/settings"
                    className={({ isActive }) =>
                        `text-sm ${isActive ? 'text-blue-600' : 'text-gray-600 hover:text-blue-600'}`
                    }
                >
                    Settings
                </NavLink>
            </Navbar.Collapse>
        </Navbar>
    );
};
