import { QueryClientProvider } from '@tanstack/react-query';
import { Flowbite } from 'flowbite-react';
import { StatusProvider } from './contexts/Status';
import { useQueryClient } from './hooks/useQueryClient';
import AppRoutes from './routes';

const App = () => {
  const queryClient = useQueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <StatusProvider>
        <Flowbite>
          <AppRoutes />
        </Flowbite>
      </StatusProvider>
    </QueryClientProvider>
  );
};

export default App;