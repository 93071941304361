import { Button, Dropdown } from 'flowbite-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { FiChevronDown, FiClipboard, FiUpload } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useStatus } from '../../../contexts/Status';
import { useClientManagement } from '../../../hooks/useClientManagement';
import { useWorkflowActions } from '../../../hooks/useWorkflowActions';
import type { Client, ClientCompany } from '../../../types/client';

interface ContextItemProps {
  label: string;
  value: string | null;
  onClick?: () => void;
  showFilter?: boolean;
}

const TopNavigation = memo(() => {
  const navigate = useNavigate();
  const { 
    Email_Address,
    Is_Super_Admin,
    Client_ID, 
    Company_ID,
    Client_Name,
    Company_Name, 
    Set_Client_Context,
    Clear_Client_Context,
    logout
  } = useStatus();
  const { Clients, Client_Companies, Is_Loading: Is_Client_Loading } = useClientManagement();
  const { Pending_Count } = useWorkflowActions();
  const [Is_Loading, Set_Is_Loading] = useState(false);

  const companies = useMemo(() => 
    Client_ID ? Client_Companies[Client_ID] || [] : [],
    [Client_ID, Client_Companies]
  );

  const hasMultipleClients = Clients.length > 1;
  const hasMultipleCompanies = companies.length > 1;

  const Handle_Sign_Out = useCallback(async () => {
    Set_Is_Loading(true);
    try {
      await logout();
      Clear_Client_Context();
      navigate('/signin');
    } catch (error) {
      console.error('Failed to sign out:', error);
    } finally {
      Set_Is_Loading(false);
    }
  }, [Clear_Client_Context, navigate, logout]);

  const Handle_Client_Change = useCallback((client: Client) => {
    Set_Client_Context(client.Client_ID, '');
  }, [Set_Client_Context]);

  const Handle_Company_Change = useCallback((company: ClientCompany) => {
    if (Client_ID) {
      Set_Client_Context(Client_ID, company.Company_ID);
    }
  }, [Set_Client_Context, Client_ID]);

  const Navigate_To_Onboarding = useCallback(() => navigate('/admin/onboarding'), [navigate]);
  const Navigate_To_Approvals = useCallback(() => navigate('/admin/approvals'), [navigate]);
  const Navigate_To_Super_Dashboard = useCallback(() => {
    Clear_Client_Context();
  }, [Clear_Client_Context]);
  const Navigate_To_Home = useCallback(() => navigate('/admin/dashboard'), [navigate]);

  // Placeholder handlers for future filter implementation
  const handleLocationFilter = useCallback(() => {
    console.log('Location filter clicked');
  }, []);

  const handleDepartmentFilter = useCallback(() => {
    console.log('Department filter clicked');
  }, []);

  const ContextItem: React.FC<ContextItemProps> = ({ 
    label, 
    value, 
    onClick, 
    showFilter = false 
  }) => (
    <span className="text-gray-500">
      {label}:{' '}
      <span 
        className={`text-gray-900 ${showFilter ? 'group cursor-pointer' : ''}`}
        onClick={onClick}
      >
        {value || '-'}
        {showFilter && (
          <FiChevronDown className="inline ml-1 w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity" />
        )}
      </span>
    </span>
  );

  const renderContextInfo = () => (
    <div className="flex items-center space-x-6 text-sm">
      <ContextItem 
        label="Email"
        value={Email_Address}
      />

      <span className="text-gray-500">
        Client:{' '}
        {hasMultipleClients ? (
          <Dropdown
            label={Client_Name || 'Select Client'}
            disabled={Is_Loading || Is_Client_Loading}
            className="inline-block"
          >
            {Clients.map((client: Client) => (
              <Dropdown.Item
                key={client.Client_ID}
                onClick={() => Handle_Client_Change(client)}
              >
                {client.Client_Name}
              </Dropdown.Item>
            ))}
          </Dropdown>
        ) : (
          <span className="text-gray-900">{Client_Name || '-'}</span>
        )}
      </span>

      <span className="text-gray-500">
        Company:{' '}
        {hasMultipleCompanies ? (
          <Dropdown
            label={Company_Name || 'Select Company'}
            disabled={Is_Loading || Is_Client_Loading || !Client_ID}
            className="inline-block"
          >
            {companies.map((company: ClientCompany) => (
              <Dropdown.Item
                key={company.Company_ID}
                onClick={() => Handle_Company_Change(company)}
              >
                {company.Company_Name}
              </Dropdown.Item>
            ))}
          </Dropdown>
        ) : (
          <span className="text-gray-900">{Company_Name || '-'}</span>
        )}
      </span>

      <ContextItem 
        label="Location"
        value="All"
        onClick={handleLocationFilter}
        showFilter={true}
      />

      <ContextItem 
        label="Department"
        value="All"
        onClick={handleDepartmentFilter}
        showFilter={true}
      />
    </div>
  );

  return (
    <div className="flex justify-between items-center px-6 py-3 bg-white border-b shadow-sm">
      {/* Left section with logo and context info */}
      <div className="flex items-center gap-6">
        <div className="cursor-pointer flex items-center" onClick={Navigate_To_Home}>
          <img src="../../assets/logo512.png" alt="Logo" className="h-8 w-auto" />
        </div>

        {renderContextInfo()}
      </div>

      {/* Right section with actions */}
      <div className="flex items-center gap-4">
        <button
          onClick={Navigate_To_Onboarding}
          title="Upload Data"
          className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
        >
          <FiUpload className="h-5 w-5" />
        </button>

        <button
          onClick={Navigate_To_Approvals}
          title="Pending Approvals"
          className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 relative"
        >
          <FiClipboard className="h-5 w-5" />
          {Pending_Count > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {Pending_Count > 99 ? '99+' : Pending_Count}
            </span>
          )}
        </button>

        {Is_Super_Admin && (
          <Button
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
            onClick={Navigate_To_Super_Dashboard}
          >
            Super Dashboard
          </Button>
        )}

        <Button
          color="gray"
          onClick={Handle_Sign_Out}
          disabled={Is_Loading}
        >
          <HiOutlineLogout className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
});

TopNavigation.displayName = 'TopNavigation';

export default TopNavigation;