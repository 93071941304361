import { useEffect, useState } from 'react';
import { workflowModule } from '../api';
import { APIResponse, ErrorMessage, createErrorMessage } from '../api/core/types';
import { useStatus } from '../contexts/Status';
import { useAdminStatus } from '../hooks/useAdminStatus';
import type { Action } from '../types';

export const useWorkflowActions = () => {
  const [Pending_Count, Set_Pending_Count] = useState(0);
  const [Completed_Count, Set_Completed_Count] = useState(0);
  const [Is_Loading, Set_Is_Loading] = useState(false);
  const [Error_Message, Set_Error_Message] = useState<ErrorMessage | null>(null);
  const [Meta, Set_Meta] = useState<APIResponse<Action>['meta'] | null>(null);
  const [Query_Context, Set_Query_Context] = useState<APIResponse<Action>['data']['Query_Context'] | null>(null);
  const [Analytics, Set_Analytics] = useState<APIResponse<Action>['data']['Analytics'] | null>(null);
  const { Company_ID, Email_Address } = useStatus();
  const { Has_Client_Context } = useAdminStatus();

  useEffect(() => {
    const Fetch_Action_Metrics = async () => {
      if (!Company_ID) {
        Set_Pending_Count(0);
        Set_Completed_Count(0);
        return;
      }

      try {
        Set_Is_Loading(true);
        Set_Error_Message(null);

        // Fetch all actions for admin view, or just user's actions for regular view
        const params = Has_Client_Context ? 
          { Company_ID } : 
          { Company_ID, Active_Approver: Email_Address || undefined };

        if (Has_Client_Context) {
          // For admin view, get company-wide stats
          const statsResponse = await workflowModule.actions.Get_Action_Stats(Company_ID);

          if (!statsResponse.success) {
            throw new Error(statsResponse.message || statsResponse.error_details);
          }

          // Store meta information and data from stats response
          const successResponse = statsResponse as APIResponse<Action>;
          Set_Meta(successResponse.meta);
          Set_Query_Context(successResponse.data.Query_Context || null);
          Set_Analytics(successResponse.data.Analytics || null);
          Set_Pending_Count(successResponse.data.Analytics?.By_Status?.['Pending'] || 0);
          Set_Completed_Count(successResponse.data.Analytics?.By_Status?.['Completed'] || 0);
        } else {
          // For user view, just get their pending actions
          const pendingResponse = await workflowModule.actions.Get_Actions({
            Company_ID,
            Active_Approver: Email_Address || undefined
          });

          if (!pendingResponse.success) {
            throw new Error(pendingResponse.message || pendingResponse.error_details);
          }

          // Store meta information and data from pending actions response
          const successResponse = pendingResponse as APIResponse<Action>;
          Set_Meta(successResponse.meta);
          Set_Query_Context(successResponse.data.Query_Context || null);
          Set_Analytics(successResponse.data.Analytics || null);
          Set_Pending_Count(successResponse.data.Records.length);
          Set_Completed_Count(0); // User view doesn't show completed actions
        }
      } catch (err) {
        const errorMsg = createErrorMessage(err, 'Failed to fetch action metrics');
        console.error('Error fetching action metrics:', errorMsg);
        Set_Error_Message(errorMsg);
        Set_Pending_Count(0);
        Set_Completed_Count(0);
        Set_Meta(null);
        Set_Query_Context(null);
        Set_Analytics(null);
      } finally {
        Set_Is_Loading(false);
      }
    };

    Fetch_Action_Metrics();
  }, [Company_ID, Email_Address, Has_Client_Context]);

  return { 
    Pending_Count,
    Completed_Count,
    Is_Loading, 
    Error_Message,
    Meta,
    Query_Context,
    Analytics
  };
};
