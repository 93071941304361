import type { Company_Analytics_Response } from "../analytics/types";
import {
    API_ENDPOINTS,
    Make_Authorized_API_Call
} from "../core/apiClient";
import type { APIResponse, APIResult } from "../core/types";
import type {
    Company,
    Company_Details,
    Company_Response,
    Create_Department_Request,
    Create_Job_Request,
    Create_Location_Request,
    Department_Detail,
    Department_With_Analytics,
    Job_Detail,
    Job_With_Analytics,
    Location_Detail,
    Location_With_Analytics,
    Update_Department_Request,
    Update_Job_Request,
    Update_Location_Request
} from './types';

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    BASE: (API_ENDPOINTS as any).COMPANY.BASE as string,
    ANALYTICS: (API_ENDPOINTS as any).ANALYTICS.ATTRITION.COMPANY as string,
    DEPARTMENT: {
        BASE: (API_ENDPOINTS as any).COMPANY.DEPARTMENT.BASE as string,
        DETAIL: (API_ENDPOINTS as any).COMPANY.DEPARTMENT.DETAIL as string
    },
    LOCATION: {
        BASE: (API_ENDPOINTS as any).COMPANY.LOCATION.BASE as string,
        DETAIL: (API_ENDPOINTS as any).COMPANY.LOCATION.DETAIL as string
    },
    JOB: {
        BASE: (API_ENDPOINTS as any).COMPANY.JOB.BASE as string,
        DETAIL: (API_ENDPOINTS as any).COMPANY.JOB.DETAIL as string
    }
} as const;

export const companyApi = {
    // Company operations
    Get_Company: async (Company_ID: string): Promise<APIResult<Company>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        return Make_Authorized_API_Call<Company>(
            `${ENDPOINTS.BASE}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    Get_Company_Analytics: async (Company_ID: string): Promise<APIResult<Company_Analytics_Response>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        console.log('[CompanyAPI] Getting analytics for Company_ID:', Company_ID);
        return Make_Authorized_API_Call<Company_Analytics_Response>(
            `${ENDPOINTS.ANALYTICS}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    Get_Company_Dashboard_Metrics: async (Company_ID: string): Promise<APIResult<Company>> => {
        console.log('[CompanyAPI] Getting dashboard metrics for Company_ID:', Company_ID);
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        // Get both base metrics and analytics
        console.log('[CompanyAPI] Fetching base metrics and analytics in parallel');
        const [metricsResponse, analyticsResponse] = await Promise.all([
            companyApi.Get_Company(Company_ID),
            companyApi.Get_Company_Analytics(Company_ID)
        ]);
        
        console.log('[CompanyAPI] Raw metrics response:', metricsResponse);
        console.log('[CompanyAPI] Raw analytics response:', analyticsResponse);

        // Handle errors
        if (!metricsResponse.success) {
            console.error('[CompanyAPI] Failed to get base metrics:', metricsResponse.message);
            return metricsResponse;
        }
        if (!analyticsResponse.success) {
            console.error('[CompanyAPI] Failed to get analytics:', analyticsResponse.message);
            return analyticsResponse;
        }

        console.log('[CompanyAPI] Successfully fetched both metrics and analytics');
        // Combine metrics with attrition data
        const baseMetrics = metricsResponse.data.Records;
        const analytics = analyticsResponse.data.Records;
        
        console.log('[CompanyAPI] Base metrics count:', baseMetrics.length);
        console.log('[CompanyAPI] Analytics count:', analytics.length);

        console.log('[CompanyAPI] Creating dashboard metrics with defaults');
        const dashboardMetrics: Company[] = baseMetrics.map((metric, index) => ({
            ...metric,
            total_risk: metric.total_risk || '0',
            total_risk_critical: metric.total_risk_critical || '0',
            count_red: metric.count_red || 0,
            count_yellow: metric.count_yellow || 0,
            count_total: metric.count_total || 0,
            count_male: metric.count_male || 0,
            count_female: metric.count_female || 0,
            count_red_critical: metric.count_red_critical || 0,
            count_yellow_critical: metric.count_yellow_critical || 0,
            count_total_critical: metric.count_total_critical || 0,
            count_male_critical: metric.count_male_critical || 0,
            count_female_critical: metric.count_female_critical || 0,
            Period_Date: metric.Period_Date || new Date().toISOString(),
            Create_Date: metric.Create_Date || new Date().toISOString()
        }));

        console.log('[CompanyAPI] Combining metrics with analytics');
        // Return in standard API response format
        const response: APIResponse<Company> = {
            success: true as const,
            message: "Successfully fetched dashboard metrics",
            meta: {
                Total_Records: dashboardMetrics.length,
                Request_ID: metricsResponse.meta.Request_ID,
                Timestamp: new Date().toISOString(),
                Company_ID
            },
            data: {
                Records: dashboardMetrics,
                Query_Context: {
                    Mode: "Dashboard",
                    Operation: "READ",
                    Company_ID
                },
                Analytics: metricsResponse.data.Analytics
            }
        };
        console.log('[CompanyAPI] Returning combined dashboard metrics:', dashboardMetrics);
        return response;
    },

    Get_Companies: async (): Promise<APIResult<Company_Response>> => {
        return Make_Authorized_API_Call<Company_Response>(
            ENDPOINTS.BASE,
            'GET'
        );
    },

    Get_Company_Detail: async (Company_ID: string): Promise<APIResult<Company_Response>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        return Make_Authorized_API_Call<Company_Response>(
            `${ENDPOINTS.BASE}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    Create_Company: async (Company_Name: string): Promise<APIResult<Company_Details>> => {
        if (!Company_Name) {
            throw new Error("Company_Name is required");
        }

        return Make_Authorized_API_Call<Company_Details>(
            ENDPOINTS.BASE,
            'POST',
            { Company_Name }
        );
    },

    Update_Company: async (Company_ID: string, data: Partial<Company_Details>): Promise<APIResult<Company_Details>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        return Make_Authorized_API_Call<Company_Details>(
            ENDPOINTS.BASE,
            'PUT',
            { ...data, Company_ID }
        );
    },

    Delete_Company: async (Company_ID: string): Promise<APIResult<void>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        return Make_Authorized_API_Call<void>(
            `${ENDPOINTS.BASE}?Company_ID=${Company_ID}`,
            'DELETE'
        );
    },

    // Department operations - 5 standard endpoints
    Get_Departments: async (Company_ID: string, Department_ID?: string): Promise<APIResult<Department_With_Analytics>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const url = Department_ID 
            ? `${ENDPOINTS.DEPARTMENT.BASE}?Company_ID=${Company_ID}&Department_ID=${Department_ID}`
            : `${ENDPOINTS.DEPARTMENT.BASE}?Company_ID=${Company_ID}`;

        return Make_Authorized_API_Call<Department_With_Analytics>(
            url,
            'GET'
        );
    },

    Get_Department_Detail: async (Company_ID: string, Department_ID?: string): Promise<APIResult<Department_Detail>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const url = Department_ID 
            ? `${ENDPOINTS.DEPARTMENT.DETAIL}?Company_ID=${Company_ID}&Department_ID=${Department_ID}`
            : `${ENDPOINTS.DEPARTMENT.DETAIL}?Company_ID=${Company_ID}`;

        return Make_Authorized_API_Call<Department_Detail>(
            url,
            'GET'
        );
    },

    Create_Department: async (request: Create_Department_Request): Promise<APIResult<Department_Detail>> => {
        if (!request.Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!request.Department_Name) {
            throw new Error("Department_Name is required");
        }

        return Make_Authorized_API_Call<Department_Detail>(
            ENDPOINTS.DEPARTMENT.DETAIL,
            'POST',
            request
        );
    },

    Update_Department: async (request: Update_Department_Request & { Company_ID: string }): Promise<APIResult<Department_Detail>> => {
        if (!request.Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!request.Department_ID) {
            throw new Error("Department_ID is required");
        }

        return Make_Authorized_API_Call<Department_Detail>(
            ENDPOINTS.DEPARTMENT.DETAIL,
            'PUT',
            request
        );
    },

    Delete_Department: async (Company_ID: string, Department_ID: string): Promise<APIResult<void>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!Department_ID) {
            throw new Error("Department_ID is required");
        }

        return Make_Authorized_API_Call<void>(
            `${ENDPOINTS.DEPARTMENT.DETAIL}?Company_ID=${Company_ID}&Department_ID=${Department_ID}`,
            'DELETE'
        );
    },

    // Location operations - 5 standard endpoints
    Get_Locations: async (Company_ID: string, Work_Location_ID?: string): Promise<APIResult<Location_With_Analytics>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const url = Work_Location_ID 
            ? `${ENDPOINTS.LOCATION.BASE}?Company_ID=${Company_ID}&Work_Location_ID=${Work_Location_ID}`
            : `${ENDPOINTS.LOCATION.BASE}?Company_ID=${Company_ID}`;

        return Make_Authorized_API_Call<Location_With_Analytics>(
            url,
            'GET'
        );
    },

    Get_Location_Detail: async (Company_ID: string, Work_Location_ID?: string): Promise<APIResult<Location_Detail>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const url = Work_Location_ID 
            ? `${ENDPOINTS.LOCATION.DETAIL}?Company_ID=${Company_ID}&Work_Location_ID=${Work_Location_ID}`
            : `${ENDPOINTS.LOCATION.DETAIL}?Company_ID=${Company_ID}`;

        return Make_Authorized_API_Call<Location_Detail>(
            url,
            'GET'
        );
    },

    Create_Location: async (request: Create_Location_Request): Promise<APIResult<Location_Detail>> => {
        if (!request.Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!request.Location_Name) {
            throw new Error("Location_Name is required");
        }

        return Make_Authorized_API_Call<Location_Detail>(
            ENDPOINTS.LOCATION.DETAIL,
            'POST',
            request
        );
    },

    Update_Location: async (request: Update_Location_Request & { Company_ID: string }): Promise<APIResult<Location_Detail>> => {
        if (!request.Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!request.Work_Location_ID) {
            throw new Error("Work_Location_ID is required");
        }

        return Make_Authorized_API_Call<Location_Detail>(
            ENDPOINTS.LOCATION.DETAIL,
            'PUT',
            request
        );
    },

    Delete_Location: async (Company_ID: string, Work_Location_ID: string): Promise<APIResult<void>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!Work_Location_ID) {
            throw new Error("Work_Location_ID is required");
        }

        return Make_Authorized_API_Call<void>(
            `${ENDPOINTS.LOCATION.DETAIL}?Company_ID=${Company_ID}&Work_Location_ID=${Work_Location_ID}`,
            'DELETE'
        );
    },

    // Job operations - 5 standard endpoints
    Get_Jobs: async (Company_ID: string, Job_ID?: string): Promise<APIResult<Job_With_Analytics>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const url = Job_ID 
            ? `${ENDPOINTS.JOB.BASE}?Company_ID=${Company_ID}&Job_ID=${Job_ID}`
            : `${ENDPOINTS.JOB.BASE}?Company_ID=${Company_ID}`;

        return Make_Authorized_API_Call<Job_With_Analytics>(
            url,
            'GET'
        );
    },

    Get_Job_Detail: async (Company_ID: string, Job_ID?: string): Promise<APIResult<Job_Detail>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const url = Job_ID 
            ? `${ENDPOINTS.JOB.DETAIL}?Company_ID=${Company_ID}&Job_ID=${Job_ID}`
            : `${ENDPOINTS.JOB.DETAIL}?Company_ID=${Company_ID}`;

        return Make_Authorized_API_Call<Job_Detail>(
            url,
            'GET'
        );
    },

    Create_Job: async (request: Create_Job_Request): Promise<APIResult<Job_Detail>> => {
        if (!request.Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!request.Job_Title) {
            throw new Error("Job_Title is required");
        }

        return Make_Authorized_API_Call<Job_Detail>(
            ENDPOINTS.JOB.DETAIL,
            'POST',
            request
        );
    },

    Update_Job: async (request: Update_Job_Request & { Company_ID: string; Job_ID: string }): Promise<APIResult<Job_Detail>> => {
        if (!request.Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!request.Job_ID) {
            throw new Error("Job_ID is required");
        }

        return Make_Authorized_API_Call<Job_Detail>(
            ENDPOINTS.JOB.DETAIL,
            'PUT',
            request
        );
    },

    Delete_Job: async (Company_ID: string, Job_ID: string): Promise<APIResult<void>> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }
        if (!Job_ID) {
            throw new Error("Job_ID is required");
        }

        return Make_Authorized_API_Call<void>(
            `${ENDPOINTS.JOB.DETAIL}?Company_ID=${Company_ID}&Job_ID=${Job_ID}`,
            'DELETE'
        );
    },

    // Camel case aliases for backward compatibility
    getCompany: async (Company_ID: string) =>
        companyApi.Get_Company(Company_ID),
    getCompanies: async () =>
        companyApi.Get_Companies(),
    getCompanyDetail: async (Company_ID: string) =>
        companyApi.Get_Company_Detail(Company_ID),
    createCompany: async (Company_Name: string) =>
        companyApi.Create_Company(Company_Name),
    updateCompany: async (Company_ID: string, data: Partial<Company_Details>) =>
        companyApi.Update_Company(Company_ID, data),
    deleteCompany: async (Company_ID: string) =>
        companyApi.Delete_Company(Company_ID),
    getDepartments: async (Company_ID: string) =>
        companyApi.Get_Departments(Company_ID),
    getDepartmentDetail: async (Company_ID: string, Department_ID: string) =>
        companyApi.Get_Department_Detail(Company_ID, Department_ID),
    createDepartment: async (request: Create_Department_Request) =>
        companyApi.Create_Department(request),
    updateDepartment: async (request: Update_Department_Request & { Company_ID: string }) =>
        companyApi.Update_Department(request),
    deleteDepartment: async (Company_ID: string, Department_ID: string) =>
        companyApi.Delete_Department(Company_ID, Department_ID),
    getLocations: async (Company_ID: string) =>
        companyApi.Get_Locations(Company_ID),
    getLocationDetail: async (Company_ID: string, Work_Location_ID: string) =>
        companyApi.Get_Location_Detail(Company_ID, Work_Location_ID),
    createLocation: async (request: Create_Location_Request) =>
        companyApi.Create_Location(request),
    updateLocation: async (request: Update_Location_Request & { Company_ID: string }) =>
        companyApi.Update_Location(request),
    deleteLocation: async (Company_ID: string, Work_Location_ID: string) =>
        companyApi.Delete_Location(Company_ID, Work_Location_ID),
    getJobs: async (Company_ID: string) =>
        companyApi.Get_Jobs(Company_ID),
    getJobDetail: async (Company_ID: string, Job_ID: string) =>
        companyApi.Get_Job_Detail(Company_ID, Job_ID),
    createJob: async (request: Create_Job_Request) =>
        companyApi.Create_Job(request),
    updateJob: async (request: Update_Job_Request & { Company_ID: string; Job_ID: string }) =>
        companyApi.Update_Job(request),
    deleteJob: async (Company_ID: string, Job_ID: string) =>
        companyApi.Delete_Job(Company_ID, Job_ID)
};
