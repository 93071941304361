import { API_ENDPOINTS, Make_Authorized_API_Call, type APIResult } from '../core';
import type {
    Approval_Config,
    Approval_Group_Email,
    Approval_Group_Email_Payload,
    Approval_Group_Params,
    Create_Approval_Config_Request,
    Get_Group_Emails_Request,
    Raw_Approval_Group,
    Update_Approval_Config_Request
} from './types';

// Extend params interfaces to satisfy Record<string, unknown>
interface Extended_Approval_Group_Params extends Record<string, unknown>, Approval_Group_Params {}
interface Extended_Email_Payload extends Record<string, unknown>, Approval_Group_Email_Payload {}
interface Extended_Group_Emails_Request extends Record<string, unknown>, Get_Group_Emails_Request {}

export const approvalApi = {
    /**
     * Get approval configurations for a company
     */
    Get_Config_Approvals: (params: { Company_ID: string }): Promise<APIResult<Approval_Config>> =>
        Make_Authorized_API_Call<Approval_Config>(
            API_ENDPOINTS.CONFIG.APPROVALS,
            'GET',
            undefined,
            { params }
        ),

    /**
     * Get approval groups for a company
     * Note: Returns Raw_Approval_Group which needs to be transformed
     */
    Get_Approval_Groups: (params: Extended_Approval_Group_Params): Promise<APIResult<Raw_Approval_Group>> =>
        Make_Authorized_API_Call<Raw_Approval_Group>(
            API_ENDPOINTS.APPROVAL_GROUP.BASE,
            'GET',
            undefined,
            { params }
        ),

    /**
     * Create a new approval configuration
     */
    Create_Config_Approval: (data: Create_Approval_Config_Request): Promise<APIResult<Approval_Config>> => {
        console.log('[approvalApi] Create_Config_Approval:', {
            method: 'POST',
            data
        });
        return Make_Authorized_API_Call<Approval_Config>(
            API_ENDPOINTS.CONFIG.APPROVALS,
            'POST',
            data
        );
    },

    /**
     * Update an existing approval configuration
     */
    Update_Config_Approval: (data: Update_Approval_Config_Request): Promise<APIResult<Approval_Config>> => {
        console.log('[approvalApi] Update_Config_Approval:', {
            method: 'PUT',
            data
        });
        return Make_Authorized_API_Call<Approval_Config>(
            API_ENDPOINTS.CONFIG.APPROVALS,
            'PUT',
            data
        );
    },

    /**
     * Delete an approval configuration
     */
    Delete_Config_Approval: (params: { Company_ID: string; Action_Group_ID: string }): Promise<APIResult<void>> =>
        Make_Authorized_API_Call<void>(
            API_ENDPOINTS.CONFIG.APPROVALS,
            'DELETE',
            undefined,
            { params }
        ),

    /**
     * Add email to approval group
     */
    /**
     * Get emails for an approval group
     */
    Get_Group_Emails: (params: Extended_Group_Emails_Request): Promise<APIResult<Approval_Group_Email>> =>
        Make_Authorized_API_Call<Approval_Group_Email>(
            API_ENDPOINTS.APPROVAL_GROUP.EMAIL,
            'GET',
            undefined,
            { params }
        ),

    /**
     * Add email to approval group
     */
    Add_Group_Email: (data: Extended_Email_Payload): Promise<APIResult<void>> =>
        Make_Authorized_API_Call<void>(
            API_ENDPOINTS.APPROVAL_GROUP.EMAIL,
            'POST',
            data
        ),

    /**
     * Remove email from approval group
     */
    Remove_Group_Email: (params: Extended_Email_Payload): Promise<APIResult<void>> =>
        Make_Authorized_API_Call<void>(
            API_ENDPOINTS.APPROVAL_GROUP.EMAIL,
            'DELETE',
            undefined,
            { params }
        )
};
