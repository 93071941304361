/**
 * Request Configuration
 */
export interface RequestConfig {
    headers?: Record<string, string>;
    getHeaders?: () => Promise<Record<string, string>>;
    params?: Record<string, unknown>;
}

/**
 * API Client Configuration
 */
export interface APIClientConfig {
    baseURL: string;
    headers?: Record<string, string>;
    onError?: (error: unknown) => void;
}

import type { Action_Field_Type_Definition } from '../../types/action';

/**
 * Success Response
 */
export interface APIResponse<T> {
    success: true;
    message: string;
    meta: {
        Total_Records: number;
        Request_ID: string;     // UUID format
        Timestamp: string;      // ISO 8601 UTC
        Company_ID?: string;
        period_date?: string;    // Keep lowercase from DB
        last_update?: string;    // Keep lowercase from DB
        [key: string]: unknown;
    };
    data: {
        Records: T[];           // Always wrap in Records array
        Query_Context?: {       // Optional operation context
            Mode?: string;        // Operation mode (e.g., "Login")
            Operation?: string;   // Operation type (e.g., "READ")
            Company_ID?: string;  // Scoping context
            Period_Date?: string; // Time context
            Request_ID?: string;  // UUID format
            Timestamp?: string;   // ISO 8601 UTC
            [key: string]: unknown;
        };
        Analytics?: {           // Optional analytics
            Total_Active: number;
            By_Status?: Record<string, number>;
            By_Risk?: Record<string, number>;
            Performance_Metrics?: {
                Total_Duration_MS: number;
                Average_Record_Duration_MS: number;
                Batches_Processed: number;
            };
            [key: string]: unknown;
        };
        Reference_Data?: {      // Optional reference data
            Field_Types?: Record<string, Action_Field_Type_Definition>;
            [key: string]: unknown;
        };
    };
}

/**
 * Error Response
 */
export interface APIErrorResponse {
    success: false;
    message: string;
    meta: {
        Timestamp: string;       // ISO 8601 UTC
        Request_ID?: string;     // UUID format
        Error_Source: string;    // Source of the error
    };
    error_code: string;        // Keep lowercase for error fields
    error_details: string;     // Keep lowercase for error fields
    validation_errors?: {      // Keep lowercase for error fields
        field: string;
        code: string;
        message: string;
    }[];
    debug?: {                  // Only in non-production
        name: string;
        message: string;
        stack: string;
    };
}

/**
 * Union type for all possible API responses
 */
export type APIResult<T> = APIResponse<T> | APIErrorResponse;

/**
 * Helper to get response data safely
 */
export function getResponseData<T>(response: APIResult<T>): T[] {
    if (!isSuccessResponse(response)) {
        throw new Error(response.message || response.error_details || "API request failed");
    }
    return response.data.Records;
}

/**
 * Type Guards
 */
export function isSuccessResponse<T>(response: APIResult<T>): response is APIResponse<T> {
    return response.success === true;
}

export function isErrorResponse<T>(response: APIResult<T>): response is APIErrorResponse {
    return response.success === false;
}

/**
 * Error Message Type
 * Always a non-empty string, never null or undefined
 */
export type ErrorMessage = string & { __brand: 'ErrorMessage' };

/**
 * Helper to ensure error message is always a string
 */
export function createErrorMessage(err: unknown, defaultMessage: string): ErrorMessage {
    if (err instanceof Error) {
        return (err.message || defaultMessage) as ErrorMessage;
    }
    return defaultMessage as ErrorMessage;
}
