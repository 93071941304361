import { API_ENDPOINTS, Make_Authorized_API_Call } from '../core';
import type {
    Get_Company_User_Entitlements_Request,
    Get_Company_User_Entitlements_Result,
    Get_User_Entitlements_Request,
    Get_User_Entitlements_Result,
    Get_User_Request,
    Get_User_Result
} from './types';

// Extend params interfaces to satisfy Record<string, unknown>
interface Extended_Company_User_Request extends Record<string, unknown>, Get_Company_User_Entitlements_Request {}
interface Extended_User_Request extends Record<string, unknown>, Get_User_Request {}
interface Extended_User_Entitlements_Request extends Record<string, unknown>, Get_User_Entitlements_Request {}

export const userApi = {
    /**
     * Get user details by email
     */
    Get_User: (params: Extended_User_Request): Promise<Get_User_Result> =>
        Make_Authorized_API_Call(
            API_ENDPOINTS.USER.BASE,
            'GET',
            undefined,
            { params }
        ),

    /**
     * Get user entitlements by company ID (for approval groups)
     */
    Get_Company_User_Entitlements: (params: Extended_Company_User_Request): Promise<Get_Company_User_Entitlements_Result> =>
        Make_Authorized_API_Call(
            API_ENDPOINTS.USER.ENTITLEMENTS,
            'GET',
            undefined,
            { params }
        ),

    /**
     * Get user entitlements by email (for status context)
     */
    Get_User_Entitlements: (params: Extended_User_Entitlements_Request): Promise<Get_User_Entitlements_Result> =>
        Make_Authorized_API_Call(
            API_ENDPOINTS.USER.ENTITLEMENTS,
            'GET',
            undefined,
            { params }
        ),
};

export * from './types';
