import { useStatus } from '../contexts/Status';

/**
 * Hook for accessing Status context in admin routes
 * 
 * This hook is only meant to be used in components that are rendered
 * within admin routes, where Company_ID is guaranteed to exist.
 * 
 * @throws Error if Company_ID is null (should never happen due to route guards)
 */
export const useAdminStatus = () => {
  const status = useStatus();

  console.log('[useAdminStatus] Current status:', {
    Company_ID: status.Company_ID,
    Has_Client_Context: status.Has_Client_Context
  });

  if (!status.Company_ID || !status.Has_Client_Context) {
    console.log('[useAdminStatus] Missing required context');
    throw new Error('Company_ID and client context are required in admin routes');
  }

  return {
    ...status,
    // Override Company_ID type to remove null
    Company_ID: status.Company_ID,
    Has_Client_Context: true as const // Override to remove false since we've checked
  } as const;
};
