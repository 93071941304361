import React, { createContext, useContext, useEffect, useState } from 'react';
import { companyApi } from '../api/company';
import type { Department_Detail, Job_Detail, Location_Detail } from '../api/company/types';
import { APIResponse, ErrorMessage, createErrorMessage } from '../api/core/types';
import { employeeApi } from '../api/employee';
import type { Employee, Employee_Response } from '../types/employee';

export interface Employee_Context_Data {
  Employees: Employee[];
  Department_Map: Record<string, string>;
  Job_Map: Record<string, string>;
  Work_Location_Map: Record<string, string>;
  Is_Loading: boolean;
  Error_Message: ErrorMessage | null;
  Meta: APIResponse<Employee_Response>['meta'] | null;
  Query_Context: APIResponse<Employee_Response>['data']['Query_Context'] | null;
  Analytics: APIResponse<Employee_Response>['data']['Analytics'] | null;
}

export interface Employee_Context_Type extends Employee_Context_Data {
  Get_Employees_By_Job: (Job_ID: string) => Employee[];
  Get_Department_Name: (ID: string) => string;
  Get_Location_Name: (ID: string) => string;
  Get_Job_Title: (ID: string) => string;
  Refresh_Data: () => Promise<void>;
}

export const EmployeeContext = createContext<Employee_Context_Type | null>(null);

export const Use_Employee = () => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error('Use_Employee must be used within an EmployeeContextProvider');
  }
  return context;
};

interface Employee_Context_Provider_Props {
  children: React.ReactNode;
  Company_ID: string;
}

// Transform Employee_Response to Employee
const transformEmployee = (response: Employee_Response): Employee => {
  // Create reference maps during transformation
  if (response.Department) {
    departmentNameMap.set(response.Department.Department_ID, response.Department.Department_Name);
  }
  if (response.Location) {
    locationNameMap.set(response.Location.Location_ID, response.Location.Location_Name);
  }
  if (response.Job) {
    jobTitleMap.set(response.Job.Job_ID, response.Job.Job_Title);
  }

  // Set default values and handle optional fields
  const employee: Employee = {
    ...response,
    Annual_Salary: response.Annual_Salary || 0,
    Date_of_Hire: response.Date_of_Hire || new Date().toISOString(),
    Work_Location_ID: response.Work_Location_ID || '',
    Department_ID: response.Department_ID || '',
    Job_ID: response.Job_ID || '',
    Period_Date: response.Period_Date || new Date().toISOString(),
    Last_Update: response.Last_Update || new Date().toISOString(),
    // Add reference data names
    Department_Name: response.Department?.Department_Name || '',
    Work_Location_Name: response.Location?.Location_Name || '',
    Job_Title: response.Job?.Job_Title || '',
    Full_Name: response.Full_Name || `${response.First_Name} ${response.Last_Name}`
  };

  return employee;
};

// Add reference maps
const departmentNameMap = new Map<string, string>();
const locationNameMap = new Map<string, string>();
const jobTitleMap = new Map<string, string>();

export const EmployeeContextProvider: React.FC<Employee_Context_Provider_Props> = ({ 
  children, 
  Company_ID 
}) => {
  console.log('[EmployeeContext] Provider mounting with Company_ID:', Company_ID);
  const [Employees, setEmployees] = useState<Employee[]>([]);
  const [Department_Map, setDepartmentMap] = useState<Record<string, string>>({});
  const [Job_Map, setJobMap] = useState<Record<string, string>>({});
  const [Work_Location_Map, setWorkLocationMap] = useState<Record<string, string>>({});
  const [Is_Loading, setIsLoading] = useState(true);
  const [Error_Message, setErrorMessage] = useState<ErrorMessage | null>(null);
  const [Meta, setMeta] = useState<APIResponse<Employee_Response>['meta'] | null>(null);
  const [Query_Context, setQueryContext] = useState<APIResponse<Employee_Response>['data']['Query_Context'] | null>(null);
  const [Analytics, setAnalytics] = useState<APIResponse<Employee_Response>['data']['Analytics'] | null>(null);

  const fetchData = async () => {
    console.log('[EmployeeContext] fetchData called with Company_ID:', Company_ID);
    if (!Company_ID) {
      console.log('[EmployeeContext] No Company_ID, skipping fetch');
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      // Fetch employees and reference data in parallel
      console.log('[EmployeeContext] Fetching employees and reference data...');
      const [
        employeeResponse,
        departmentResponse,
        locationResponse,
        jobResponse
      ] = await Promise.all([
        employeeApi.Get_Employees({ Company_ID }),
        companyApi.Get_Department_Detail(Company_ID),
        companyApi.Get_Location_Detail(Company_ID),
        companyApi.Get_Job_Detail(Company_ID)
      ]);
      
      // Type assertion since we know the response is successful
      const successResponse = employeeResponse as APIResponse<Employee_Response>;
      
      console.log('[EmployeeContext] Employee data fetched successfully:', successResponse.data.Records.length, 'records');
      
      // Build reference maps from detail responses
      console.log('[EmployeeContext] Building reference maps...');
      const deptMap: Record<string, string> = {};
      const jobMap: Record<string, string> = {};
      const locMap: Record<string, string> = {};

      if ('success' in departmentResponse && departmentResponse.success) {
        departmentResponse.data.Records.forEach((dept: Department_Detail) => {
          if (dept.Department_ID && dept.Department_Name) {
            deptMap[dept.Department_ID] = dept.Department_Name;
            departmentNameMap.set(dept.Department_ID, dept.Department_Name);
          }
        });
      }

      if ('success' in locationResponse && locationResponse.success) {
        locationResponse.data.Records.forEach((loc: Location_Detail) => {
          if (loc.Work_Location_ID && loc.Location_Name) {
            locMap[loc.Work_Location_ID] = loc.Location_Name;
            locationNameMap.set(loc.Work_Location_ID, loc.Location_Name);
          }
        });
      }

      if ('success' in jobResponse && jobResponse.success) {
        jobResponse.data.Records.forEach((job: Job_Detail) => {
          if (job.Job_ID && job.Job_Title) {
            jobMap[job.Job_ID] = job.Job_Title;
            jobTitleMap.set(job.Job_ID, job.Job_Title);
          }
        });
      }

      // Transform and store employee data
      const transformedEmployees = successResponse.data.Records.map(transformEmployee);
      setEmployees(transformedEmployees);
      console.log('[EmployeeContext] Employee data transformed');
      
      // Store meta information
      setMeta(successResponse.meta);
      setQueryContext(successResponse.data.Query_Context || null);
      setAnalytics(successResponse.data.Analytics || null);

      console.log('[EmployeeContext] Setting reference maps...');
      setDepartmentMap(deptMap);
      setJobMap(jobMap);
      setWorkLocationMap(locMap);
      console.log('[EmployeeContext] Data setup complete');
    } catch (error) {
      const errorMsg = createErrorMessage(error, 'Failed to fetch employee data');
      console.error('[EmployeeContext] Error fetching employee data:', errorMsg);
      setErrorMessage(errorMsg);
      setEmployees([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('[EmployeeContext] Company_ID changed:', Company_ID);
    fetchData();
  }, [Company_ID]);

  const Get_Employees_By_Job = (Job_ID: string): Employee[] => {
    return Employees.filter(emp => emp.Job_ID === Job_ID);
  };

  const Get_Department_Name = (ID: string): string => {
    return departmentNameMap.get(ID) || ID;
  };

  const Get_Location_Name = (ID: string): string => {
    return locationNameMap.get(ID) || ID;
  };

  const Get_Job_Title = (ID: string): string => {
    return jobTitleMap.get(ID) || ID;
  };

  const contextValue: Employee_Context_Type = {
    Employees,
    Department_Map,
    Job_Map,
    Work_Location_Map,
    Is_Loading,
    Error_Message,
    Meta,
    Query_Context,
    Analytics,
    Get_Employees_By_Job,
    Get_Department_Name,
    Get_Location_Name,
    Get_Job_Title,
    Refresh_Data: fetchData
  };

  console.log('[EmployeeContext] Rendering provider with state:', {
    Is_Loading,
    Has_Error: !!Error_Message,
    Employee_Count: Employees.length
  });

  return (
    <EmployeeContext.Provider value={contextValue}>
      {children}
    </EmployeeContext.Provider>
  );
};
