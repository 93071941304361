import React, { createContext, useContext, useState } from 'react';
import type { Action_Field_Type_Definition, Config_Action } from '../types/action';

interface ActionsContextValue {
    actions: Config_Action[];
    fieldTypes: Record<string, Action_Field_Type_Definition>;
    setActions: (actions: Config_Action[]) => void;
    setFieldTypes: (fieldTypes: Record<string, Action_Field_Type_Definition>) => void;
}

const ActionsContext = createContext<ActionsContextValue | null>(null);

export const useActions = () => {
    const context = useContext(ActionsContext);
    if (!context) {
        throw new Error('useActions must be used within an ActionsProvider');
    }
    return context;
};

interface ActionsProviderProps {
    children: React.ReactNode;
}

export const ActionsProvider: React.FC<ActionsProviderProps> = ({ children }) => {
    const [actions, setActions] = useState<Config_Action[]>([]);
    const [fieldTypes, setFieldTypes] = useState<Record<string, Action_Field_Type_Definition>>({});

    return (
        <ActionsContext.Provider
            value={{
                actions,
                fieldTypes,
                setActions,
                setFieldTypes
            }}
        >
            {children}
        </ActionsContext.Provider>
    );
};

export default ActionsContext;
